<template>
  <nh-dialog
    v-model="dialogVisible"
    v-loading="loading.init"
    title="总拣记录"
    append-to-body
    custom-class="custom-dialog_1250"
  >
    <nh-info-header
      :info="INFO"
      :data="form"
      :col="24"
      min-width="160px"
      label-width="100px"
      title=""
      class="dialog-header"
    />
    <PlTable
      :loading="loading.getReservoirList"
      :data="tableData"
      :columns="TABLECOLUMN"
      max-height="400px"
    />
  </nh-dialog>
</template>
<script>
import loadingMixin from '@thales/loading';
import { INFO, TABLECOLUMN } from './fileds';
import { TEMPERATURE_LAYERS_ENUM } from './constant';
import { pickDetailPage } from '../api';

export default {
  name: 'InWarehouseAcceptanceDiff',
  mixins: [loadingMixin],
  data() {
    return {
      dialogVisible: false,
      INFO,
      id: null,
      form: {},
      TABLECOLUMN,
      tableData: [],
      loading: {
        init: false,
      },
    };
  },
  methods: {
    async init(data) {
      this.dialogVisible = true;
      const {
        details_waveOrderId: waveOrderId, goodsId, details_batchNo: batchNo, ...form
      } = data;
      this.form = form;
      const { records = [] } = await pickDetailPage(
        { waveOrderId, goodsId, batchNo },
        { size: 200, page: 1 },
      );
      this.tableData = records.map((item) => {
        this.form.temperatureLayer = TEMPERATURE_LAYERS_ENUM[item.temperatureLayer];
        this.form.shipperGoodsCode = item.shipperGoodsCode;
        return item;
      });
    },
    onClose() {
      this.dialogVisible = false;
    },
  },
};
</script>

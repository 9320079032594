import { wmsPlusHttp } from '@/utils/http/index';

/**
 * @description: 流利架库存 分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/424/interface/api/12128
 */

export default function fluentRackStock(data, params) {
  return wmsPlusHttp.post('/warehouse_automation/fluent_rack_stock/page', data, {
    params,
  });
}
/**
 * @description: 流利架分拣-总拣明细 分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/424/interface/api/12368
 */

export function pickDetailPage(data, params) {
  return wmsPlusHttp.post('/warehouse_automation/fluent_sorting_wave_pick_detail/page', data, {
    params,
  });
}
/**
 * @description: 货品分布调整工位
 * @api文档：https://yapi.ops.yunlizhi.cn/project/424/interface/api/15335
 */

export function workLocationUpdate(data) {
  return wmsPlusHttp.post('/warehouse_automation/fluent_rack_stock/work_location/update', data);
}

import { formatKeyValueObject } from '@/utils/base';

export const TEMPERATURE_LAYERS = [
  {
    label: '常温',
    value: 'NORMAL',
  },
  {
    label: '冷藏',
    value: 'REFRIGERATE',
  },
  {
    label: '冷冻',
    value: 'COLD',
  },
  {
    label: '恒温',
    value: 'CONSTANT',
  },
];
export const TEMPERATURE_LAYERS_ENUM = formatKeyValueObject(TEMPERATURE_LAYERS);

<template>
  <pl-block>
    <PlForm
      v-model="searchForm"
      mode="search"
      :fields="serchFields"
      @submit="handleSearch"
      @reset="handleReset"
    >
      <template #shipperName>
        <nh-shipper-selector v-model:shipper="shipper" />
      </template>
    </PlForm>
  </pl-block>
  <pl-block>
    <PlTable
      v-model:pagination="pagination"
      :loading="loading.getTableData"
      :data="tableData"
      :columns="TABLECOLUMN"
      :show-table-setting="true"
      :span-method="handleSpanMethod"
      @update:pagination="handlePaginationChange"
    >
      <template #action="{row}">
        <nh-button type="text" @click="handleRecord(row)">
          总拣记录
        </nh-button>
        <nh-button
          v-if="!row.details_locationCode"
          type="text"
          @click="handleStation(row)"
        >
          工位调整
        </nh-button>
      </template>
    </PlTable>
    <record-view ref="recordDialog" />
    <station-dialog ref="stationDialog" @success="getTableData" />
  </pl-block>
</template>
<script>
import loadingMixin from '@thales/loading';
import recordView from './components/recordView.vue';
import stationDialog from './components/stationDialog.vue';
import {
  DICTIONARIES,
  STORE_TYPE,
  SCATTER,
  SORTING_LINE,
  TABLECOLUMN,
  serchFields,
} from './fileds';
import fluentRackStock from './api';

export default {
  name: 'WarehouseZone',
  components: { recordView, stationDialog },
  mixins: [loadingMixin],
  data() {
    return {
      DICTIONARIES,
      TABLECOLUMN,
      STORE_TYPE,
      SCATTER,
      SORTING_LINE,
      serchFields,
      tableData: [],
      searchForm: {},
      loading: {
        getTableData: false,
      },
      shipper: null,
    };
  },
  watch: {
    shipper(shipper) {
      this.setCustomerName(shipper?.shipperName);
    },
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      this.getTableData();
    },
    handleReset() {
      this.resetSearchFrom();
      this.getTableData();
    },
    resetSearchFrom() {
      this.resetShipper();
      this.resetPage();
    },
    handleSearch() {
      this.resetPage();
      this.getTableData();
    },
    resetShipper() {
      this.shipper = null;
    },
    setCustomerName(shipperName) {
      this.searchForm.shipperName = shipperName;
    },
    resetPage() {
      this.pagination.page = 1;
    },
    handlePaginationChange() {
      this.getTableData();
    },
    async getTableData() {
      this.tableData = [];
      const lists = [];
      const pagination = {
        page: this.pagination.page,
        size: this.pagination.size,
      };
      const {
        total = 0,
        records = [],
      } = await fluentRackStock(this.searchForm, pagination) || {};
      this.pagination.total = total;
      records.forEach((item) => {
        const { details, ext, ...subtasks } = item;
        // 相同批次排序及设置合并行
        const mergeBatchNoList = this.mergeBatchNo(details);

        if (mergeBatchNoList && mergeBatchNoList.length >= 1) {
          mergeBatchNoList.forEach((list, index) => {
            const newList = {};

            Object.keys(list).forEach((key) => {
              newList[`details_${key}`] = list[key];
            });
            if (mergeBatchNoList.length > 1) {
              if (index === 0) {
                newList.rowspan = mergeBatchNoList.length;
              }
            } else {
              newList.rowspan = 1;
            }
            lists.push({
              ...subtasks,
              ...ext,
              ...newList,
            });
          });
        } else {
          lists.push({ ...subtasks, ...ext, rowspan: 1 });
        }
      });
      this.tableData = lists.map((item) => ({
        ...item,
        details_sortingUnits: this.sortingUnits(item.details_storeType),
      }));
    },
    sortingUnits(val) {
      if (val) {
        return val === SCATTER.value ? '零单位' : '整单位';
      }
      return '';
    },
    mergeBatchNo(details) {
      const lists = [];
      details.forEach((item) => {
        const index = lists.map((list) => list.batchNo).lastIndexOf(item.batchNo);
        if (index < 0) {
          lists.push({
            ...item,
            rowspan: details.filter((list) => list.batchNo === item.batchNo).length || 1,
          });
        } else {
          lists.splice(index + 1, 0, item);
        }
      });
      return lists;
    },
    handleSpanMethod({ row, columnIndex, column }) {
      if (columnIndex >= 0 && columnIndex <= 4) {
        if (row.rowspan) {
          return [row.rowspan, 1];
        }
        return [0, 0];
      }
      // 合并批次
      if (column.property === TABLECOLUMN[5].prop) {
        if (row.details_rowspan) {
          return [row.details_rowspan, 1];
        }
        return [0, 0];
      }
      return [1, 1];
    },
    handleRecord(row) {
      this.$refs.recordDialog.init(row);
    },
    handleStation(row) {
      this.$refs.stationDialog.init(row);
    },
  },
};
</script>

// import unitNumber from '@/utils/unitNumber';
import moment from '@/utils/moment';

// function calculateNumber(row) {
//   return row.smallUnitNumber ? unitNumber.calculate(row).txt : '';
// }

const INFO = [{
  label: '货主货品编码：',
  prop: 'shipperGoodsCode',
  width: 7,
}, {
  label: '货品名称：',
  prop: 'goodsName',
  width: 7,
}, {
  label: '货主：',
  prop: 'shipperName',
  width: 5,
}, {
  label: '温层：',
  prop: 'temperatureLayer',
  width: 5,
}];

export const formFileds = [
  {
    label: '波次号：',
    prop: 'waveOrderNo',
    colAttrs: {
      span: 24,
    },
  }, {
    label: '原工位：',
    prop: 'details_workLocationCode',
    colAttrs: {
      span: 24,
    },
  }, {
    label: '库区类型：',
    prop: 'details_type',
    colAttrs: {
      span: 12,
    },
  }, {
    label: '分拣单位：',
    prop: 'details_sortingUnits',
    colAttrs: {
      span: 12,
    },
  },
  {
    label: '调整为：',
    prop: 'targetWorkLocationCode',
    colAttrs: {
      span: 12,
    },
  },
];

export { INFO };

export const TABLECOLUMN = [{
  label: '序号',
  type: 'index',
  width: 50,
}, {
  label: '托盘码',
  prop: 'containerCode',
  minWidth: 80,
}, {
  label: '是否上线',
  prop: 'needFluentSorting',
  minWidth: 120,
  formatter: ({ needFluentSorting }) => (needFluentSorting ? '是' : '否'),
}, {
  label: '指定批次',
  prop: 'batchNo',
  minWidth: 120,
}, {
  label: '总拣数量',
  prop: 'amount',
  minWidth: 80,
  // formatter: (row) => calculateNumber({ ...row, smallUnitNumber: row.amount }),
}, {
  label: '剩余数量',
  prop: 'remainAmount',
  width: 80,
  // formatter: (row) => calculateNumber({ ...row, smallUnitNumber: row.remainAmount }),
}, {
  label: '总拣时间',
  prop: 'createTime',
  minWidth: 120,
  formatter: ({ createTime }) => moment.format(createTime),
}];

import { FormType } from '@/constant/form';
import { formatKeyValueObject } from '@/utils/base';

const DICTIONARIES = {
  SORTING_LINE: 'sortingLine',
  STORE_TYPE: 'details_storeType',
};
const SORTING_LINE = [{
  value: 'NO1',
  label: '一号线',
}, {
  value: 'NO2',
  label: '二号线',
}];
const SCATTER = {
  value: 'SCATTER',
  label: '拆零区',
};
const STORE_TYPE = [{
  value: 'PALLET',
  label: '整托区',
}, {
  value: 'BOX',
  label: '整箱区',
},
SCATTER,
];
export {
  DICTIONARIES,
  SORTING_LINE,
  SCATTER,
  STORE_TYPE,
};
export const STORE_TYPE_ENUM = formatKeyValueObject(STORE_TYPE);
function filterLabel(val, arr) {
  const label = arr && arr.find((item) => item.value === val);
  return label && label.label;
}

export const TABLECOLUMN = [{
  label: '序号',
  type: 'index',
  width: 50,
}, {
  label: '波次号',
  prop: 'waveOrderNo',
  minWidth: 120,
}, {
  label: '货主',
  prop: 'shipperName',
  minWidth: 120,
}, {
  label: '货品编码',
  prop: 'goodsCode',
  minWidth: 120,
}, {
  label: '货品名称',
  prop: 'goodsName',
  minWidth: 120,
}, {
  label: '指定批次',
  prop: 'details_batchNo',
  minWidth: 120,
}, {
  label: '流利架库位',
  prop: 'details_locationCode',
  minWidth: 120,
}, {
  label: '区域类型',
  prop: 'details_storeType',
  minWidth: 120,
  formatter: (row) => filterLabel(row.details_storeType, STORE_TYPE),
}, {
  label: '分拣线',
  prop: DICTIONARIES.SORTING_LINE,
  minWidth: 120,
  formatter: (row) => filterLabel(row.sortingLine, SORTING_LINE),
}, {
  label: '分拣单位',
  prop: 'details_sortingUnits',
  minWidth: 120,
}, {
  label: '分配量',
  prop: 'details_assignedAmount',
  minWidth: 120,
}, {
  label: '待补货量',
  prop: 'details_replenishAmount',
  minWidth: 160,
}, {
  label: '库存量',
  prop: 'details_amount',
  minWidth: 160,
}, {
  label: '占用量',
  prop: 'details_lockedAmount',
  minWidth: 160,
},
{
  label: '操作',
  prop: 'action',
  fixed: 'right',
  minWidth: 160,
},
];
export const serchFields = [
  {
    label: '波次号',
    prop: 'waveOrderNo',
    component: FormType.INPUT,
  }, {
    label: '货主',
    prop: 'shipperName',
  }, {
    label: '货品名称',
    prop: 'goodsName',
    component: FormType.INPUT,
  }, {
    label: '批次号',
    prop: 'batchNo',
    component: FormType.INPUT,
  }, {
    label: '流利架库位',
    prop: 'locationCode',
    component: FormType.INPUT,
  }, {
    label: '分拣线',
    prop: DICTIONARIES.SORTING_LINE,
    component: FormType.SELECT,
    options: SORTING_LINE,
  },
];

<template>
  <nh-dialog
    v-model="dialogVisible"
    v-loading="loading.handleSubmit"
    title="工位调整"
    append-to-body
    @close="handleBeforeClose"
  >
    <PlForm
      v-model="ruleForm"
      mode="normal"
      :fields="formFileds"
      :collapse="false"
      submit-btn-text="确 认"
      reset-btn-text="取 消"
      :form-props="{
        rules: rules
      }"
      @submit="handleSubmit('editForm')"
      @reset="handleClose"
    >
      <template #waveOrderNo>
        <div class="dialog-header">
          {{ ruleForm.waveOrderNo }}
        </div>
      </template>
      <template #details_workLocationCode>
        <div class="dialog-header">
          {{ ruleForm.details_workLocationCode }}
        </div>
      </template>
      <template #details_type>
        <div class="dialog-header">
          {{ ruleForm.details_type }}
        </div>
      </template>
      <template #details_sortingUnits>
        <div class="dialog-header">
          {{ ruleForm.details_sortingUnits }}
        </div>
      </template>
      <template #targetWorkLocationCode>
        <el-select
          v-model="ruleForm.targetWorkLocationCode"
          filterable
          placeholder="请输入流利架工位号"
        >
          <el-option
            v-for="item in WORK_LOCATION_NO_CODE"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </template>
    </PlForm>
  </nh-dialog>
</template>
<script>
import loadingMixin from '@thales/loading';
import mixin from './mixin';
import { formFileds } from './fileds';
import { STORE_TYPE_ENUM } from '../fileds';
import { workLocationUpdate } from '../api';

export default {
  name: 'InWarehouseAcceptanceDiff',
  mixins: [loadingMixin, mixin],
  emits: ['success'],
  data() {
    return {
      dialogVisible: false,
      formFileds,
      id: null,
      ruleForm: {
        targetWorkLocationCode: null,
      },
      rules: {
        targetWorkLocationCode: [
          { required: true, message: '请选择', trigger: 'blur' },
        ],
      },
      loading: {
        handleSubmit: false,
      },
    };
  },
  methods: {
    async init(data) {
      this.dialogVisible = true;
      this.ruleForm = {
        ...this.ruleForm,
        ...data,
        details_type: STORE_TYPE_ENUM[data.details_storeType],
      };
    },
    async handleSubmit() {
      await workLocationUpdate({
        targetWorkLocationCode: this.ruleForm.targetWorkLocationCode,
        waveOrderId: this.ruleForm.details_waveOrderId,
        workLocationCode: this.ruleForm.details_workLocationCode,
        goodsId: this.ruleForm.goodsId,
        batchNo: this.ruleForm.details_batchNo,
      });
      this.$message({
        type: 'success',
        message: '修改成功!',
      });
      this.$emit('success');
      this.handleClose();
    },
    handleClose() {
      this.dialogVisible = false;
    },
    handleBeforeClose() {
      this.ruleForm = { targetWorkLocationCode: null };
    },
  },
};
</script>
<style scoped lang="scss">
.dialog-header {
  font-weight: 600;
  color: #171717;
}
</style>
